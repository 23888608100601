.login-checkbox {
  text-align: center;
  margin-top: 6px;
}

.text-center {
  text-align: center;
  &.mar-15 {
    margin-bottom: 15px;
  }
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #2e9fff;
  border-radius: 50%;
  margin-left: 10px;
  display: inline-block;
}

.footer-attorney {
  z-index: 999;
  position: relative;
}

.item-24 {
  font-size: 24px;
}

.caution-header {
  padding: 15px 5px;
  background: linear-gradient(to right, #fffb82, #fffb82);
  border-radius: 5px;
  margin: 5px 0px 10px 0px;
  border: 1px solid #d5c913;
  #p {
    margin-bottom: 0;
    color: #666;
  }
}

.bg-caution {
  background-image: linear-gradient(
    120deg,
    #f77f03 0%,
    #f77f03 100%
  ) !important;
}

.caution-cross {
  float: right;
  font-weight: bolder !important;
  margin-top: -10px;
}

.tel {
  padding-top: 28px;
}

.caution-icon {
  position: relative;
  width: 90% !important;
  float: right !important;
}

.icon-btn {
  text-align: center;
  border: 0;
  &.btn {
    margin-bottom: 5px;
    width: 105px;
    color: #ffffff;
    padding: 10px;
    display: inline-block;
    font-size: 12px;
    border: 0;
  }
}

.icon-btn > a {
  background-color: #545454;
}

.icon-size {
  font-weight: bold !important;
  vertical-align: middle !important;
  &.wh {
    color: white !important;
  }
  &.cross {
    font-size: 22px;
  }
}

.btn-secondary.wrap {
  background-color: transparent;
}

.rc-tabs-top .rc-tabs-nav-wrap.db-tab {
  padding: 1.5rem 0px !important;
}

.marg-10 {
  margin-left: -15px;
}

.tab-db {
  .rc-tabs-top .rc-tabs-nav-wrap {
    padding-left: 0px;
  }
}

.icon-attorney {
  margin-right: 5px;
  vertical-align: text-top;
  &.help {
    font-weight: bold !important;
    color: #1563f1;
    font-size: 20px;
    vertical-align: text-top;
  }
  &.warning {
    vertical-align: bottom;
  }
  &.positive {
    color: darkgreen;
    font-weight: bold !important;
    font-size: 20px;
  }
  &.negative {
    color: #b71c1c;
    font-weight: bold !important;
    font-size: 20px;
  }
  &.special {
    vertical-align: middle;
  }
}

.more-details {
  padding-left: 10px;
  padding-top: 10px;
  font-weight: 700;
}

.calendar-label {
  color: black !important;
  padding-top: 7px;
}

.total-col {
  color: #545cd8;
  font-weight: bold;
}

.percent-color {
  color: green;
  &.neg {
    color: red;
  }
}

.cross-bg {
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  vertical-align: middle;
  padding: 2px;
}

.action-plan {
  margin-left: 15px;
}

.mar-20 {
  margin-right: 20px;
}

.unset-li {
  list-style: unset !important;
}

.bg-help {
  background-image: linear-gradient(120deg, blue 0%, blue 100%);
}

.mar-left {
  margin-left: 20px;
}

.progress-bar-circ-container svg {
  width: 75px !important;
  height: 75px !important;
}

.progress-bar-percent {
  font-size: 20px !important;
}

.credit-heading {
  border-bottom: 0 !important;
}
