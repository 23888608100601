.attorny_main 
{
    & {
        padding-top: 0 !important;
    }
    .attorney-sidebar { 
        position: static;
        margin-top: 0;
        z-index: 2;
        height: auto;
    }  
    .app-main__outer{
        padding-left: 0 !important;
    }
}


ul.suggestion {
    border: 1px solid #000000;
    padding: 10px;
    background-color: #27444f;
}


ul.suggestion:empty {
    display: none;
}

span.Or {
    display: block;
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
    font-weight: 600;
}

.attorney-search .form-group label {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    line-height: 30px;
}

span.redstar {
    color: red;
}

.app-main.attorny_main .icon-item .btn {
    margin-right: 20px;
}

.attorny_main .actions .btn {
    width: 100%;
}

ul.suggestion li > a {color: #ffffff;font-size: 16px;padding-bottom: 12px;display: block;font-weight: 600;}

// Attorney css

.request-block ul li label {
    display: inline-block;
    min-width: 150px;
    font-size: 16px;
    font-weight: 600;
}

.request-block ul li span {
    display: inline-block;
    max-width: 700px;
}

.request-block ul li {
    padding-bottom: 10px;
    display: block;
    vertical-align: middle;
}

.mb-10.request-block {
    border: 1px solid #0000002e;
    padding: 10px;
}

.form-block {
    padding: 10px;
}

.special-pricing{
    max-width: 900px !important;
}

.attorney-detail{
    max-width: 700px !important;
}

.action-items .btn {
    margin: 0 15px 16px 0;
    border-radius: 0;
}