@import "../styles/custom-variable";
body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

button {
    cursor: pointer !important;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

img {
    max-width: 100%;
}

.header-shadow .icon-wrapper {
    top: 0;
}

button.p-0.btn.btn-link>img {
    display: none;
}

#nprogress .bar {
    height: 5px;
}

.logo-header {
    width: 120px;
    padding-top: 10px;
}

// Remove tooglle
button.jsx-3928250682.BurgerSlider.Burger {
    display: none;
}

.admin-app {
    padding: $base-larg-padding;
    background-color: $white;
    max-width: 550px;
    border-radius: 5px;
    h2 {
        font-size: $font-heading-h2;
        font-weight: $font-weight-bold;
        margin: 0;
        padding: 0;
    }
    .rc-tabs-top .rc-tabs-nav-wrap {
        padding-left: 0;
    }
    .login-button {
        button {
            background: $color-light-green;
            font-size: $font-base;
            border: none;
            outline: none;
            margin-top: 6px;
        }
        button:hover,
        button:focus {
            background: $color-light-green;
            border: none;
            outline: none;
        }
    }
    .error {
        color: $required-red;
        position: absolute;
        bottom: -5px;
        font-size: $font-extra-small;
    }
}

.setting-block .nav-top-wrapper {
    display: none;
}

.logs>h2 {
    font-size: $font-large;
    font-weight: 600;
    padding: 30px 0 20px 0;
}

.language-icon .mr-3 {
    margin: 0 0 0 8px !important;
    max-width: 36px;
}

.grid-menu.grid-menu-3col [class*="col-"]:nth-last-child(-n+3) {
    border-bottom-width: 1px !important;
}

.white-bg {
    // background-color: #f1f5fa !important;
    background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%) !important;
}

// Loader CSS

/* Make clicks pass-through */

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}


/* Fancy blur effect */

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}


/* Remove these to get rid of the spinner */

#nprogress .spinner {
    display: block;
    position: absolute;
    margin: 0 auto;
    z-index: 8000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#nprogress .spinner-icon {
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border: solid 4px transparent;
    border-top-color: #ef898a;
    border-left-color: #29d;
    margin: 0 auto;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

html.nprogress-busy:before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    height: 500vh;
    background-color: #0000008f;
}

html.nprogress-busy {
    overflow: hidden;
}

.billing-info-form {
    padding: 30px 0;
}

.blue-bg {
    background: linear-gradient(180deg, #382457, #7ae837);
}

// Button css
.actions .btn:first-child {
    margin-right: 3px;
}

.actions>a {
    background-color: #545cd8;
}

.actions .btn {
    margin-bottom: 5px;
    width: 105px;
    color: #ffffff;
    padding: 10px;
    display: inline-block;
    font-size: 12px;
    border: 0;
}

#nprogress .bar {
    height: 4px;
}

ul.pagination {
    justify-content: flex-end;
    margin-top: 30px;
}

.title>h2 {
    text-align: left;
    font-size: 21px;
    text-transform: uppercase;
    padding: 20px 0 15px 0;
}

// Admin Header css
.header-dots.admin-controls-header .grid-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header-dots.admin-controls-header .grid-menu>a {
    min-width: auto;
    color: #fdfdff;
    font-size: 15px;
    font-weight: normal;
    border: 2px solid #a9291f66;
    padding: 5px 8px;
    margin-right: 10px;
    border-radius: 5px !important;
    background-color: transparent;
    white-space: nowrap;
}

.header-dots.admin-controls-header .grid-menu>a:hover {
    color: #fdfdff;
    border: 2px solid #fea9a2;
    background-color: #b34840ed;
}

.header-dots.admin-controls-header .grid-menu>a>.bg-night-fade {
    background-image: linear-gradient(to top, #fdfdff 0%, #fdfdff 100%) !important;
}

// User name
span.user-name {
    display: block;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 8px;
    word-break: break-all;
    text-align: center;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
}