.card-wrapper.card {
    padding: 10px;
    margin-bottom: 10px !important;
}

.desc-body p {
    font-size: 15px;
    color: #000000;
}

section.notest-section .card {
    padding: 10px;
    width: 100% !important;
    margin-bottom: 10px;
    margin : 10px 0 !important;
}

.desc-body ul {
    list-style: none;
}

.majorsub-block label {
    display: inline-block;
    padding-right: 20px;
    min-width: 70px;
}

.majorsub-block:first-child {
    padding-top: 15px;
}

.loader-block:before {content: '';position: absolute;top: 0;right: 0;left: 0;bottom: 0;background-color: #fb898c54;}

.font-icon-wrapper.float-left.mr-3.mb-3 {
    position: absolute;
    right: 0;
    left: 0;
    border: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
}

.loader-block .line-scale-pulse-out > div{
    width: 30px;
    height: 100px;
}

.loader-block .loader-wrapper{
    width: 300px;
    height: 108px;
    float: none;
    margin: 0 auto;
}
.loader-block .font-icon-wrapper:hover{
    background-color: transparent;
}

.loader-block {
    overflow: hidden;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
}