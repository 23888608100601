body{
    font-family: 'Nunito', sans-serif !important; 
}
.page-title-actions{
    display: none;
}
.logs{
    .app-page-title{
        margin: -30px -30px 0;
    }
}