.credit-heading {
    margin: 0;
    padding-left: 0;
}

.common-module .card-title {
    line-height: 30px;
    font-size: 1.2rem;
    font-weight: 600;
}

ul.disclosures-listing {
    margin: 20px 0;
}

ul.disclosures-listing>li {
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 10px;
    position: relative;
    padding-left: 20px;
}

ul.info-listing {
    margin: 20px 0;
}

ul.info-listing>li {
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 10px;
    position: relative;
    padding-left: 20px;
}

ul.info-listing>li:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #636363;
    top: 7px;
    border-radius: 50%;
    left: 0;
}

ul.button-list li {
    display: inline-block;
    margin: 10px 20px 10px 0;
}

.attorney-block {
    padding-top: 20px;
    width: 300px;
}

.attorney-block>li:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #000000;
    border-radius: 50%;
    top: 7px;
    left: 0;
}

ul.attorney-block>li {
    position: relative;
    padding-left: 22px;
}

.step-5 .main-card {
    padding: 20px;
}

ul.courses-list-block.mb-10 {
    padding-top: 18px;
}

ul.courses-list-block.mb-10>li {
    padding-bottom: 11px;
    font-weight: 600;
}

.final-registration ul {
    padding: 20px 0;
}

.final-registration ul>li {
    padding-bottom: 15px;
    font-weight: 600;
}

.red-block>span {
    color: #ffffff;
    font-weight: 600;
    background-color: red;
    padding: 4px;
    margin-bottom: 20px;
    display: block;
}

.progress-bar {
    background-color: transparent;
}