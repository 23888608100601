.waiver.couseling>p {
    padding-bottom: 20px;
    line-height: 24px;
}

.ml-20 {
    margin-left: 20px;
}

.form-width {
    width: 40%;
    @include respond-to('desktop-sm') {
        width: 100%;
    }
}

.account-details {
    .wrapper.row {
        margin: 0;
    }
}