@import './_custom-variable.scss';
*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-size: $font-base;
}

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  margin: 0;
  padding: 0;
}

h1,
h3,
h3,
h4,
h5 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-style: normal;
  font-size: $font-heading-h1;
}

h2 {
  font-style: normal;
  font-size: $font-heading-h2;
}

h3 {
  font-style: normal;
  font-size: $font-heading-h3;
}

h4,
.page-title,
.app-page-title .page-title-heading {
  font-style: normal;
  // font-size: $font-heading-h2;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 30px;
  font-weight: normal;
  width: 100%;
  text-align: left;
  text-transform: capitalize !important;
  color: black;
  @include respond-to('desktop-sm') {
    font-size: $font-heading-h3;
  }
}

.title-attorney {
  color: black;
}

.antique-header h4 {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 22px;
}

.attorney-font-family {
  font-family: 'Nunito', sans-serif !important;
}

p {
  font-size: $font-base;
  line-height: $line-height-base;
  font-weight: normal;
  margin: 0;
}

ul {
  margin: 0px;
}

li {
  list-style: none;
}

label {
  font-style: normal;
  font-weight: $font-weight-m;
  font-size: $font-base;
  line-height: 22px;
  margin: 0px;
  color: #5d6063;
}

input {
  font-size: $font-base;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

textarea {
  font-size: $font-base;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

input::placeholder,
textarea::placeholder {
  font-size: $font-base;
  color: gainsboro !important;
}

button {
  font-size: $font-base;
}

table {
  tr {
    th {
      min-width: 140px;
      max-width: 150px;
      font-size: $font-base;
    }
    td {
      font-size: $font-base;
    }
  }
}

.welcome-img {
  width: 310px;
  float: left;
  padding: 0 20px 20px 0;
}

.graphic-box img {
  width: 65%;
}

.step2-heading {
  .page-title-heading {
    font-size: 22px;
  }
}

.width-full {
  width: 100%;
}

.width-sel {
  width: 300px;
}

.rect-progress-bar-percent {
  font-size: 13px;
  margin: 5px;
}

.credit-heading {
  padding-top: 10px;
}

// .progress-bar {
//     top: 96px;
//     right: 0;
//     position: absolute !important;
// }

.progress-bar-rect-wrap-container {
  display: flex;
  align-items: center;
  position: absolute;
  width: 250px;
  right: 0;
  top: -6px;
  @include respond-to('desktop-sm') {
    position: inherit;
    width: 100%;
  }
}

.step-1img {
  margin-top: 4rem;
}

.modal-dialog.calculator {
  max-width: 740px;
}

.modal-dialog.calculator .modal-content {
  padding: 20px;
}

.modal-dialog.calculator .modal-content h1 {
  font-size: 25px;
  padding: 30px 0 20px 0;
}

div#KJEAllContent {
  font-size: 15px;
  padding-bottom: 20px;
}

h5.app-sidebar__heading {
  padding-left: 5px;
  background: #375a69;
  padding: 10px;
}

.counselor-wrapper .notes-btns button {
  @include respond-to('desktop-sm') {
    margin-bottom: 15px;
  }
}

.attorney-home-btn {
  float: right;
  @include respond-to('desktop-sm') {
    float: none;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.app-page-title .page-title-heading {
  margin-bottom: 0 !important;
}

.logs .app-page-title .page-title-heading {
  border-bottom: 0;
}

.debtor-education .container-fluid,
.counselors .container-fluid,
.notest-section .card-body,
.folio-view-debtor .container-fluid {
  padding: 0 !important;
}

.debtors-container {
  margin: 0 !important;
}

.logs .app-page-title {
  padding-bottom: 0;
}

.link-item,
button {
  @include respond-to('desktop-sm') {
    display: inline-block;
    margin-bottom: 10px;
  }
}

.total-row{
  font-weight: bold;
  #custom-th{
    border-right: none;
  }
}
  