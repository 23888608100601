.app-middle-section {
    padding: $base-larg-padding 0;

    .Home-text{
        p{
            font-size: $font-base;
            padding: 10px 0;
            line-height: 29px;
        }
    }
}



button.nav-link.logout.btn.btn-secondary {
    background-color: transparent;
    border: 0;
    outline: 0;
}

ul.course-list {
    list-style: none;
}

ul.course-list > li > a {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    display: block;
    padding: 10px 0;
}

ul.course-list > li {
    padding-left: 22px;
    position: relative;
}

ul.course-list > li:before {content: '';width: 10px;position: absolute;height: 10px;background-color: #ffffff;top: 18px;border-radius: 50%;left: 0;}