
.account-details{
    h2 {
        font-weight: 600;
        padding: 20px 0;
    }
    span.label {
        display: block;
        padding: 6px 0;
    }
}

.userBlock .invalid-feedback {
  display: block;
}


.change-password {
    max-width: 502px;
    margin: 0 auto;
}radialGradientr


ul.sub-menu {
    padding-left: 20px;
}


.change-password .heading {
    font-size: 26px;
    padding-bottom: 20px;
    font-weight: 600;
}

.Home-text > h1 {
    font-size: $font-heading-h1;
    font-weight: $font-weight-b;
} 

.Home-text p {
    font-size: $font-base;
}

.Home-text {
    max-width: 509px;
    background-color: $color-primary;
    color: $white;
    padding: $font-medium;
    margin: 0 auto;
    border-radius: $border-radius-base;
}

// Loader

    .br {
        border-radius: 8px;  
    }
  .w80 {
     width: 80%;
  }
  .card {
    border: 2px solid #fff;
    box-shadow:0px 0px 10px 0 #a9a9a9;
   
  }
  .wrapper {
    width: 0px;
    animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  .profilePic {
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }
  
  .comment {
    height: 10px;
    background: #777;
    margin-top: 20px;
  }
  
  @keyframes fullView {
    100% {
      width: 100%;
    }
  }


ul.courses-list > li > a {
  color: #000000;
  font-size: 18px;
  position: relative;
}

ul.courses-list > li {
  padding-bottom: 11px;
  list-style: none;
}

.courses-list {
  text-align: left;
  padding-left: 15px;
  padding-bottom: 15px;
}

.app-middle-section.change-password {
  margin: initial;
}

ul.courses-list > li > a:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #352b5d;
  border-radius: 50%;
  top: 7px;
  left: -18px;
}

.courses-list-block > p {
  padding-bottom: 10px;
}
  .animate {
     animation : shimmer 2s infinite linear;
     background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 1000px 100%;
  }
  
    @keyframes shimmer {
        0% {
        background-position: -1000px 0;
        }
        100% {
        background-position: 1000px 0;
        }
  }

  span.answer-value {
    display: inline-block;
    padding-left: 30px;
    font-weight: 600;
}