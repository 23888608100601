.footer {
  background-color: $greybrd;
  padding-top: $base-larg-padding;
  .heed-help {
    h4 {
      font-weight: bold;
      color: $color-black-primary;
    }
    p {
      text-align: justify;
      color: $color-black-primary;
    }
  }
  a {
    color: $black;
    padding-left: 10px;
  }
  a:hover {
    text-decoration: none;
    color: $pink;
  }

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      padding: 10px 0px;
      border-bottom: 1px solid $bordercolor;
      position: relative;
      font-size: $font-base;
      color: $color-black-primary;
      span {
        padding-left: 10px;
        color: $color-black-primary;
      }
      .open-menu::after {
        content: '\2212';
        font-size: 20px;
        position: absolute;
        right: 20px;
      }
      .close-menu::after {
        content: '\002B';
        font-size: 20px;
        position: absolute;
        right: 20px;
      }
    }
  }

  .sub-menu li {
    border-bottom: 0px solid $bordercolor;
  }
  .sub-menu li::before{
    content: '\00BB';
  }

  .menu.first-child .open-menu::after {
    display: none !important;
    visibility: hidden;
  }
}
.footer-bottom {
  border-top: 1px solid $bordercolor;
  ul li {
    border: none;
    display: inline-block;
    a {
      padding: 0px 10px;
      @include respond-to('desktop-sm') {
        padding: 0px 6px;
      }
    }
  }
}
.footer .footer-bottom ul li:before {
  content: ' ';
}

.footer.main-wrapper {
  background: $color-gray-secondary;
  ul {
    li {
      color: $white;
      a {
        color: $white;
      }
      a:hover {
        color: $white;
      }
      span {
        color: $white;
      }
    }
  }
  .heed-help {
    h4 {
      text-transform: uppercase;
      color: $white;
    }
    p {
      color: $white;
    }
  }
  .menu {
    .open-menu:hover,
    .close-menu:hover,
    .expanded-menu:hover {
      color: $white;
      cursor: pointer;
    }
  }

  .footer-bottom ul li {
    font-size: $font-small;
    color: $white;
  }
}
