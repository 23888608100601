.navigation-wrap.bg-light {
  background: $color-primary !important;
}

.navigation-wrap.bg-light .navbar-brand {
  color: $white;
  font-weight: bold;
}

.navigation-wrap.bg-light .navbar-light .navbar-nav .nav-link {
  color: $white;
}

.icon-wrapper {
  top: 9px;
  margin-left: auto;
  margin-right: 0;
}

header.header-section {
  height: 115px;
  background-color: #7ae837;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.444111);
}

.logo-header {
  p {
    color: $white;
    font-weight: $font-weight-bold;
    margin: 0;
    // padding-top: 16px;
  }
}

.lang-dropdown {
  position: relative;
  top: 10px;
}
