.sub-heading .page-title-heading {
    font-size: 20px;
    font-weight: bold;
}

.heading-nav {
    font-weight: bold;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
}

.nav-containt-box table {
    text-align: left;
    width: 100%;
}

.nav-containt-box table tr td {
    text-align: left;
}

.heading-box {
    border-bottom: 1px solid #CACACA;
    padding: 8px 0;
}

.heading-nav.open-icon::before {
    background-image: url('./images/close.png');
}

.heading-nav.close-icon:before {
    background-image: url('./images/open.png');
}

.heading-nav:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 3px;
}

.sub-nav-box {
    padding: 15px 10px;
    background: #fdfdfd;
    a {
        display: block;
        position: relative;
        padding-left: 20px;
        font-size: 14px;
    }
    a::after {
        position: absolute;
        width: 5px;
        height: 5px;
        background: #d8d7d7;
        content: '';
        top: 7px;
        left: 0;
    }
}

.nav-containt-box {
    h2 {
        border-bottom: 1px solid #CACACA;
        margin-bottom: 25px;
    }
    #show {
        margin-bottom: 30px;
    }
    ul {
        padding-left: 20px;
    }
    ul li {
        list-style: disc;
        padding: 4px;
    }
}

.question-wrapper {
    padding: 30px 0;
    @include respond-to('desktop-sm') {
        margin: 15px;
    }
    h2 {
        border-bottom: 1px solid #CACACA;
        margin-bottom: 25px;
    }
    .select-answer label {
        // padding-left: 40px;
        font-size: 16px;
    }
}

.question-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

span.red-mark {
    display: block;
    padding: 0 0 20px 0;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

.green-mark {
    color: green;
}


/* Hide the browser's default radio button */

.question-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.question-box:hover input~.checkmark {
    background-color: #ccc;
}


/* When the radio button is checked, add a blue background */

.question-box input:checked~.checkmark {
    background-color: #2196F3;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.question-box input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.question-box .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.form-width {
    width: 40%;
    @include respond-to('desktop-sm') {
        width: 100%;
    }
}