
*,body{
    margin:0; 
    padding:0;
    box-sizing: border-box;
  }
html,
body {
    height: 100%;
}


//Colors//

//Color-testing-variable//
$color-primary: #352a5d;
$color-secondary: #352a5d;
$color-secondary-orang:#60e412;
// $color-secondary-orang:#47862b;
// $color-secondary-orang:#6db14f;

//  Color-testing-variable end//

// $color-primary: #fb898c;
// $color-secondary: #DE6262;
// $color-secondary-orang: #FFB88C;

$color-gray-secondary:#2b2e39;
$color-black-primary:#212529;

$white:#ffffff;
$black: #000000;
$black-primary:#00000069;
$ltgrey:#dbdbdb;
$greybrd: #f1f5fa;
$color-green: #1FAA12;
$color-light-green:#60e412;
$color-gray :#F8F9FB;
$color-light-w2: #F7F8FA;
$bordercolor:#c2c2c2;
$placeholder:#8F8F8F;
$border-color:#CACACA; 
$color-black-secondary:#202020;
$color-light:#e6e6e6;
$color-white-light: #fafafa;
$color-secondary-light: #F8F9FB;
$color-orange:#DF5F5F;
$color-light-gray:#d9e2f4;
$color-light-red: #E63D18;
$color-light-pink: rgb(173, 20, 87);
$color-pink: #FF5959;
$required-red:#FF0000;
$color-yellow: #FDC006;
$color-primary-light : rgba(64, 111, 202, 0.1);
$color-tab-bg: #E3EAF7;


//font-size//
$font-base: 16px !default;
$font-medium: ceil(($font-base + 2px)) ;  // ~ 18px;
$font-large: ceil(($font-base + 6px )) ;  // ~  22px; 
$font-small: ceil(($font-base - 2px )) ;   //   14px;
$font-extra-small:  ceil(($font-base - 3px )) ;  // 13px;

//Font heading 
$font-heading-h1: ceil(($font-base + 16px)) ;  //  32px; 
$font-heading-h2: ceil(($font-base + 14px)) ;  // ~  30px;
$font-heading-h3: ceil(($font-base + 4px )) ;  // ~  20px;
$font-heading-h4: ceil(($font-base + 2px)) ;  // ~ 18px;
$font-heading-h5: $font-base; // 16px ;
$font-heading-h6: ceil(($font-base - 2px )) ;   //   14px;

// LineHeight for base
$line-height-base: ceil(($font-base + 8px)) ; // ~  24px;
$line-height-medium: ceil(($font-medium + 10px)) ;  // ~  28px;
$line-height-large: ceil(($font-large + 10px)) ;  // ~ 32px;
$line-height-small:  ceil(($font-small + 10px)) ;  // ~  24px;
$line-height-extra-small: ceil(($font-extra-small + 10px)) ;  // ~  23px;

//height 
$base-height:48px !default;
$base-small-height: ceil(($base-height - 16px)) ;   // 32px;
$base-extra-small-height: ceil(($base-height - 24px)) ;   //   24px;
$base-height-large: ceil(($base-height + 16px)) ;   //   64px;
$header-height-space: 80px;
$medium-height:56px;

//font-weight//

$font-weight-s:400;
$font-weight-m:500;
$font-weight-b:600;
$font-weight-bld:700;
$font-weight-bold:900;

// border-radius status

$border-radius-base: 4px !default;
$border-radius-medium: ceil(($border-radius-base + 2px)) ;   //    6px ;
$border-radius-large: ceil(($border-radius-base + 6px)) ;   //   10px ;
$border-radius-small-larg: 12px ;
$border-radius-small: 3px ;
$border-radius-circle: 50% ;


// Common Padding Variables
$baseSpace : 24px !default; //  <<<== don't use this $baseSpace variable in css file.

$base-padding: $baseSpace ;  //    24px; 
$base-small-padding:  ceil(($base-padding - 8px)) ;   //   16px;
$base-medium-padding: ceil(($base-padding - 12px)) ;   //     12px;
$base-larg-padding: ceil(($base-padding + 6));   //30px;
$base-xtlarg-padding: ceil(($base-padding + 16));   //40px;
$base-extra-small-padding:8px;
$case-inner-space:56px; 

// Common margin Variable
$base-margin: $baseSpace ;  //    24px; ; 
$base-small-margin: ceil(($base-margin - 8px)) ;   //    16px;
$base-medium-margin: ceil(($base-margin - 12px)) ;  //  12px;
$base-extra-small-margin:8px;

// Other margin paddding variables 
$otherBaseSpace: 12px !default; // <<<== don't use this $otherBaseSpace variable in css file.

$margin-1 : ceil(($otherBaseSpace - 8px)) ;  // 4px;
$margin-2 : ceil(($otherBaseSpace - 4px)) ;  // 8px;
$margin-3 : $otherBaseSpace ;  // 12px;
$margin-4 : ceil(($otherBaseSpace + 4px)) ;  // 16px;
$margin-5 : ceil(($otherBaseSpace + 10px)) ;  // 22px;
$margin-6: ceil(($otherBaseSpace - 2px)) ;  // 10px;
$margin-7: ceil(($otherBaseSpace - 1px)) ;  // 11px;
$margin-8 : ceil(($otherBaseSpace + 8px)) ;  // 20px;

$padding-1 : ceil(($otherBaseSpace - 8px)) ;  // 4px;
$padding-2 : ceil(($otherBaseSpace - 4px)) ;  // 8px;
$padding-3 : $otherBaseSpace ;  // 12px;
$padding-4 : ceil(($otherBaseSpace + 4px)) ;  // 16px;
$padding-5 : ceil(($otherBaseSpace + 10px)) ;  // 22px;
$padding-6: ceil(($otherBaseSpace - 2px)) ;  // 10px;
$padding-7: ceil(($otherBaseSpace - 1px)) ;  // 11px;
$padding-8 : ceil(($otherBaseSpace + 8px)) ;  // 20px;


