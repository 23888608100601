.setting-block{
        


    .about-block > h3 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.100em;
    }
    
    .setting-block .navbar {
        display: none;
    }
    
    .setting-block .navbar {
        display: none;
    }
    
    .card .tools-link {
        margin: 10px;
        text-align: center;
        background-color: #8890b9;
        padding: 10px;
        color: #ffffff;
        font-weight: 600;
        box-shadow: none;
        display: block;
    }
    
    .about-block .infotext {
        list-style: none;
        padding: 10px;
        background-color: #f09589;
        color: #ffffff;
        font-weight: 600;
    }
    
    .about-block > p {
        font-size: 19px;
    }

    .resetting-setting-block, .about-block {
        padding: 15px;
    }   

    ul.infotext {
        list-style-type: none;
    }
}

