@import "../styles/custom-variable";
.login-block {
    background: $color-secondary;
    background: linear-gradient(to bottom, #382457, #7ae837);
    width: 100%;
    width: 100%;
    padding: 50px 0;
    .login-wrapper {
        background: $white;
        @include respond-to('desktop-sm') {
            .row.mobile-view {
                flex-direction: column-reverse;
            }
        }
    }
    .second-wrap {
        background: $black;
        background-size: cover;
        min-height: 500px;
        padding: 0;
        @include respond-to('desktop-sm') {
            border-radius: $border-radius-large $border-radius-large 0 0;
        }
    }
    .login-button {
        margin-left: $margin-8;
        margin-top: -8px;
        &.top {
            margin-top: -6px!important;
        }
        @include respond-to('desktop-sm') {
            margin: 0;
        }
        button {
            background: $color-secondary-orang;
            font-size: $font-base;
            border: none;
            outline: none;
            @include respond-to('desktop-sm') {
                float: right;
                width: 100%;
            }
        }
        button:hover,
        button:focus {
            background: $color-secondary-orang;
            border: none;
            outline: none;
        }
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        background: $color-secondary-orang;
        border: none;
        outline: none;
    }
    .forgot-pass {
        padding-left: $padding-4;
        display: flex;
        align-items: center;
        @include respond-to('desktop-sm') {
            display: block;
            width: 100%;
        }
    }
    .form-group {
        position: relative;
    }
    .error {
        color: $required-red;
        position: absolute;
        bottom: 0px;
        top: 64px;
        font-size: $font-extra-small;
    }
    .carousel-inner {
        border-radius: 0 $border-radius-large $border-radius-large 0;
    }
    .login-sec {
        padding: $padding-8 $base-small-padding $base-larg-padding $base-xtlarg-padding;
        position: relative;
        @include respond-to('desktop-sm') {
            position: relative;
            order: 1;
            padding: $padding-8 $base-xtlarg-padding $base-larg-padding $base-xtlarg-padding;
        }
        .alert.alert-primary {
            width: 100%;
            padding: $padding-6 4px $padding-6 $base-extra-small-padding;
        }
    }
    .login-sec .copy-text {
        position: absolute;
        width: 80%;
        bottom: 20px;
        font-size: $font-extra-small;
        text-align: center;
    }
    .login-sec .copy-text i {
        color: $color-secondary-orang;
    }
    .login-sec .copy-text a {
        color: $color-secondary;
    }
    .login-sec h2 {
        margin-bottom: 30px;
        font-weight: $font-weight-bold;
        color: $color-light-green;
    }
    .login-sec h2:after {
        content: " ";
        width: 100px;
        height: 5px;
        background: $color-secondary;
        display: block;
        margin-top: $margin-6;
        border-radius: $border-radius-small;
        margin-left: auto;
        margin-right: auto;
    }
    .footer.main-wrapper ul li span:hover {
        color: #ffffff !important;
    }
    ul.sub-menu li>a:hover {
        color: #ffffff !important;
    }
    .btn-login {
        background: $color-secondary;
        color: $white;
        font-weight: $font-weight-b;
    }
    .banner-text {
        width: 100%;
        padding: $base-xtlarg-padding $base-larg-padding 2px $base-larg-padding;
    }
    .checklabel-wrapper {
        label {
            font-size: $font-small;
        }
        button {
            background: $color-light-green;
            font-size: $font-base;
            border: none;
            outline: none;
            // float:right;
            margin-left: 108px;
            @include respond-to('desktop-md') {
                width: 100%;
            }
            @include respond-to('desktop-sm') {
                width: auto;
            }
        }
    }
    .banner-text h2 {
        color: $white;
        font-weight: $font-weight-bold;
    }
    .banner-text h2:after {
        content: " ";
        width: 100px;
        height: 5px;
        background: $white;
        display: block;
        margin-top: $margin-6;
        margin-bottom: $margin-4;
        border-radius: $border-radius-small;
    }
    .banner-text p {
        color: $white;
    }
    .reset_username {
        color: $white;
        font-weight: $font-weight-bold;
    }
    .reset-pass {
        background: $color-secondary;
        color: $white;
        font-weight: $font-weight-b;
        border: none;
        outline: none;
        padding: 4px 10px;
        border-radius: $border-radius-small;
        font-size: $font-small;
    }
}

// responsive 
@include respond-to('desktop-md') {}

@include respond-to('desktop-sm') {}